import React from "react"
import MyMap from '../components/MyMap';
import styled from 'styled-components';
import logo from '../theme/logo.svg';

const IndexPage = () => (
  <StyledApp>
    <div
      style={{
        display: 'flex',
        backgroundColor: '#2a2a90',
        zIndex: 10,
        verticalAlign: 'text-top'
      }}>
      <img src={logo} alt='logo' style={{ width: '180px', height: '80px' }} />
      <div style={{
        flexGrow: 0.9,
        verticalAlign: 'top',
        textAlign: 'right',
        color: 'white',
        fontWeight: 'normal'
      }} >
        Application web réalisée par Vuong BUI<br />
        Candidat au poste de Juriste - droit du stationnement
        </div>
    </div>
    <div><MyMap /></div>
  </StyledApp >
)

const StyledApp = styled.div`
      display: grid;
      grid-template-rows: 50px auto
    `

export default IndexPage
