import React, { useState } from 'react'
import { GoogleMap, LoadScript, MarkerClusterer, Marker, InfoWindow } from '@react-google-maps/api'
import parkingLocations from '../ParkingList';

const MyMap = () => {
  // eslint-disable-next-line
  const [center, setCenter] = useState({
    lat: 50.844,
    lng: 4.355
  });

  const [infoWindowIsShown, setinfoWindowIsShown] = useState({});

  const onToggleInfoWindow = (key) => {
    setinfoWindowIsShown({
      [key]: !infoWindowIsShown[key]
    });

  };

  return (
    <LoadScript
      id="script-loader"
      language="fr"
      googleMapsApiKey="AIzaSyB4loTjOMtkonbu9gipVZ8OlAaRJy2Eo4E"
    >
      <GoogleMap
        id="circle-example"
        clickableIcons={false}
        mapContainerStyle={{
          height: "90vh",
          width: "97vw"
        }}
        zoom={13}
        center={center}
      >
        <MarkerClusterer
          options={{ minimumClusterSize: 3, maxZoom: 15 }}
        >
          {
            (clusterer) => parkingLocations.map((location, i) => (
              <Marker
                key={i}
                position={{ 'lat': location.lat, 'lng': location.lng }}
                title={location.name}
                clusterer={clusterer}
                clickable={true}
                onClick={() => onToggleInfoWindow(location.place_id)}
              >


                {(infoWindowIsShown.hasOwnProperty(location.place_id) && infoWindowIsShown[location.place_id]) ? (
                  <InfoWindow
                    options={{ disableAutoPan: true }}
                    position={{ 'lat': location.lat, 'lng': location.lng }}
                    onCloseClick={() => onToggleInfoWindow(location.place_id)}
                  >
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      background: `white`,
                      padding: 15
                    }}
                    >
                      <a href={location.hasOwnProperty('share_link') ? location.share_link : null}>
                        <img
                          src={location.hasOwnProperty('image_url') ? location.image_url : "https://maps.gstatic.com/tactile/pane/default_geocode-1x.png"}
                          alt={location.name}
                        />
                      </a>
                      <h2>{location.name}</h2>
                      <div>{location.address}</div>
                    </div>
                  </InfoWindow>) : null
                }



              </Marker>
            ))
          }
        </MarkerClusterer>

      </GoogleMap>
    </LoadScript >
  )
}

export default MyMap;